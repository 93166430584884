<template>
  <div>
    <el-steps :active="step" finish-status="success" simple style="margin-bottom: 20px">
      <el-step title="添加发货工单" ></el-step>
      <el-step title="添加设备" ></el-step>
    </el-steps>
    <div>
      <delivery-work-edit v-if="step === 0" @close-edit-modal="onNext" />
      <device-edit v-if="step === 1" :read-only="true" :data="data" @close-edit-modal="onCloseDialog('edit', true)" />
    </div>
  </div>
</template>

<script>
import DeviceEdit from "@/components/device-edit";
import DeliveryWorkEdit from "@/views/after-sales/delivery-work/edit";

export default {
  components: {
    DeviceEdit,
    DeliveryWorkEdit
  },
  data () {
    return {
      step: 0,
      data: {
        information: '',
        adminName: '',
        adminMobile: '',
        tenantId: '',
        businessId: '',
        projectType: '',
        companyName: '',
        code: '',
        remarks: '',
        area: "",
      }
    }
  },
  created () {
    console.log("admin-edit");
  },
  methods: {
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    onNext (data) {
      this.step++;

      this.data.information = data.headName;
      this.data.adminName = data.tabulationName;
      this.data.projectType = data.deviceType;
      this.data.adminMobile = data.headPhone;
      this.data.tenantId = data.tenantId;
      this.data.businessId = data.businessId;
      this.data.companyName = data.company;
      this.data.deviceGroup = data.code;
      this.data.area = data.area;
      this.data.codes = data.codes;
    },
    onCloseDialog () {
      this.$emit("close-edit-modal");
    }
  }
}
</script>