var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-steps",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { active: _vm.step, "finish-status": "success", simple: "" },
        },
        [
          _c("el-step", { attrs: { title: "添加发货工单" } }),
          _c("el-step", { attrs: { title: "添加设备" } }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.step === 0
            ? _c("delivery-work-edit", {
                on: { "close-edit-modal": _vm.onNext },
              })
            : _vm._e(),
          _vm.step === 1
            ? _c("device-edit", {
                attrs: { "read-only": true, data: _vm.data },
                on: {
                  "close-edit-modal": function ($event) {
                    return _vm.onCloseDialog("edit", true)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }