<template>
  <div v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="130px" class="edit-items-box" style="max-height: 560px; overflow:auto;">
      <el-form-item  prop="headName" label="设备名称">
        <el-input v-model="form.headName" clearable size="mini" maxlength="20" placeholder="请输入设备名称" />
      </el-form-item>
      <el-form-item  prop="deviceType" label="设备类型">
        <el-select size="mini" style="width:100%" v-model="form.deviceType" clearable placeholder="请选择设备类型" @change="onSelectProjectType">
          <el-option v-for="item in pTypes" :key="item.name" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <device-edit-code :data="null" :form="form" :multiple="true" />
      <biz-edit ref="bizEdit" :is-admin="user.tenantId === 0" :dataObj="form" @select-company="onSelectCompnay" />
      <el-form-item  prop="consignee" label="收货人姓名">
        <template slot="label">
          <span>收货人姓名</span>
          <el-tooltip class="item" placement="right" style="margin-left: 5px;">
            <i class="el-icon-warning" style="font-size: 16px; vertical-align: middle;"></i>
            <div slot="content">请填写自己的真实姓名</div>
          </el-tooltip>
        </template>
        <el-input v-model="form.consignee" clearable size="mini" placeholder="请输入收货人姓名" />
      </el-form-item>
      <el-form-item  prop="customerPhone" label="客户电话">
        <el-input v-model="form.customerPhone" clearable size="mini" maxlength="11" placeholder="请输入客户电话" />
      </el-form-item>
      <el-form-item  prop="address" label="收货地址">
        <el-input v-model="form.address" clearable size="mini" placeholder="请输入收货地址" />
      </el-form-item>
      <el-form-item  prop="deliveryTime" label="发货时间">
        <el-date-picker type="date" v-model="form.deliveryTime" clearable size="mini" placeholder="请选择发货时间" />
      </el-form-item>
      <el-form-item  prop="tabulationName" label="负责人姓名">
        <el-input v-model="form.tabulationName" clearable size="mini" placeholder="请输入负责人姓名" />
      </el-form-item>
      <el-form-item  prop="headPhone" label="负责人电话">
        <template slot="label">
          <span>负责人电话</span>
          <el-tooltip class="item" placement="right" style="margin-left: 5px;">
            <i class="el-icon-warning" style="font-size: 16px; vertical-align: middle;"></i>
            <div slot="content">请填写自己的真实姓名</div>
          </el-tooltip>
        </template>
        <el-input v-model="form.headPhone" clearable size="mini" maxlength="11" placeholder="请输入负责人电话" />
      </el-form-item>
      <el-form-item  prop="testName" label="测试人员姓名">
        <template slot="label">
          <span>测试人员姓名</span>
          <el-tooltip class="item" placement="right" style="margin-left: 5px;">
            <i class="el-icon-warning" style="font-size: 16px; vertical-align: middle;"></i>
            <div slot="content">请填写自己的真实姓名</div>
          </el-tooltip>
        </template>
        <el-input v-model="form.testName" clearable size="mini" placeholder="请输入测试人员姓名" />
      </el-form-item>
      <el-form-item  prop="testPhone" label="测试人员电话">
        <el-input v-model="form.testPhone" clearable size="mini" maxlength="11" placeholder="请输入测试人员电话" />
      </el-form-item>
      <el-form-item  prop="area" label="所在地">
        <el-select placeholder="请选择所在地" v-model="form.area" style="width:100%;" size="mini" clearable :disabled="isI1 || readOnly">
          <el-option v-for="area in areaList" :key="area" :value="area" :label="area"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item  prop="remarks" label="备注">
        <el-input v-model="form.remarks" clearable size="mini" type="textarea" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <dialog-footer reset-access="device-edit-reset-btn" submit-access="device-edit-edit-btn" @reset="onReset" @submit="onSubmit" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import bizApi from "@/api/business";
import { addDeliveryWork } from "@/api/project";

import BizEdit from "@/components/business/edit";
import DeviceEditCode from "@/components/device-edit/code";

export default {
  components: {
    BizEdit,
    DeviceEditCode
  },
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      loading: false,
      form: {
        code: "",
        headName: "",
        deviceType: "",
        codes: [],
        tenantId: "",
        businessId: "",
        company: "",
        consignee: "",
        customerPhone: "",
        address: "",
        tabulationName: "",
        headPhone: "",
        testName: "",
        testPhone: "",
        area: "",
        remarks: ""
      },
      rules: {
        headName:  [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ],
        codes: [
          {
            validator: (rule, value, callback) => {
              if (this.form.codes.length === 0) {
                callback("请选择一个设备编号");
                return;
              }
              callback();
            }
          }
        ],
        tenantId: [
          { required: true, message: '请选择一个公司分类', trigger: 'change' }
        ],
        businessId: [
          { required: true, message: '请选择一个公司', trigger: 'change' }
        ],
        consignee:  [
          { required: true, message: '请输入客户姓名', trigger: 'blur' }
        ],
        customerPhone:  [
          { required: true, message: '请输入客户电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ],
        address:  [
          { required: true, message: '请输入收货地址', trigger: 'blur' }
        ],
        deliveryTime:  [
          { required: true, message: '请选择发货时间', trigger: 'blur' }
        ],
        tabulationName:  [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' }
        ],
        headPhone:  [
          { required: true, message: '请输入负责人电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ],
        testName:  [
          { required: true, message: '请输入测试人员姓名', trigger: 'blur' }
        ],
        testPhone:  [
          { required: true, message: '请输入测试人员电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ],
        area: [
          { required: true, message: '请选择所在地', trigger: 'blur' }
        ]
      },

      areaList: $statics.AREA_LIST,
      pTypes: $statics.PROJECT_TYPE_LIST
    }
  },
  created () {
    this.selectGroup();
  },
  methods: {

    async selectGroup () {
      try {
        this.loading = true;
        const businessId = this.user.businessId;

        const res = await bizApi.list({
          size: 999999,
          page: 1,
          tenantId: this.user.tenantId,
          id: this.user.businessId
        });

        for (const company of res.data) {
          if (company.id === businessId) {
            this.company = company;
            break;
          }
        }
      } finally {
        this.$refs.form.clearValidate(Object.keys(this.form));
        this.loading = false;
      }
    },
    onSelectProjectType () {

      if (!this.form.businessId) {
        return;
      }

      this.createDeviceName();
    },
    async createDeviceName () {
      if (this.form.projectName) return;

      this.loading = true;

      let compnayCode = null;
      switch (this.company.tenantId) {
        case 0:
          compnayCode = `I${this.company.id}`;
          break;
        case 1:
          compnayCode = `P${this.company.id}`;
          break;
        case 2:
          compnayCode = `C${this.company.id}`;
          break;
      }

      this.form.projectName = compnayCode + "_" + Date.now();
      this.loading = false;
    },
    loadCompanyList (list) {
      this.companyList = list;
    },
    onSelectCompnay (company) {

      this.company = company;

      if (!company) return;

      this.form.company = this.company.companyName;

      if (!$utils.checkNumberIsNotEmpty(this.form.projectType)) return;

      if (company.version === "I1") {
        this.isI1 = true;
        this.form.area = "浙江";
      } else {
        this.isI1 = false;
        this.form.area = "";
      }

      this.createDeviceName();
    },
    async onClosed () {
      return await $utils.closeDialogConfirm(this);
    },
    async onReset () {
      if (this.data) {
      this.form = Object.assign(this.form, JSON.parse(cache));
      } else {
      this.$refs.form.resetFields();
      }
      if (this.user.tenantId === 0) {
        await this.$refs.bizEdit.onReselect(this.form.businessId);
      }
      this.$forceUpdate();
    },
    onSubmit () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          const workObj = Object.assign({}, this.form);

          const requests = [];
          for (const code of workObj.codes) {
            const obj = Object.assign({}, workObj);
            obj.code = code;
            obj.deliveryTime = $utils.formatTime(obj.deliveryTime, "YYYY-MM-DD");
            delete obj.codes;
            requests.push(addDeliveryWork(obj));
          }

          const reses = await Promise.all(requests);
          
          // workObj.businessId = reses[0];
          this.$emit("close-edit-modal", workObj);
        } finally {
          this.loading = false;
        }
      });
    }
  }
}
</script>