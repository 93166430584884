<template>
  <div class="big-data-container" v-loading="loading">
    <div class="top-search-box p-b-0 m-b-20">
      <el-form ref="search" :model="searchParams" label-width="120px">
      <el-row>
        <el-col :span="span">
          <el-form-item label="设备编号">
            <el-input v-model="searchParams.code" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="设备类型">
            <el-select v-model="searchParams.projectType" style="width:100%" clearable placeholder="请选择设备类型" size="mini" @change="onSearch">
              <el-option v-for="item in projectTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <business-search  :span="span" v-if="user.tenantId === 0" :dataObj="searchParams" @change="onSearch" />
        <el-col :span="span">
          <el-form-item label="升级状态">
            <el-select v-model="searchParams.updateStatus" style="width:100%" clearable placeholder="请选择升级状态" size="mini" @change="onSearch">
              <el-option v-for="status in updateStatusList" :key="status.value" :label="status.label" :value="status.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="在线状态">
            <el-select v-model="searchParams.onlineStatus" style="width:100%" clearable placeholder="请选择在线状态" size="mini" @change="onSearch">
              <el-option v-for="status in onlineStatusList" :key="status.value" :label="status.label" :value="status.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="告警状态">
            <el-select v-model="searchParams.alarmStatus" style="width:100%" clearable placeholder="请选择告警状态" size="mini" @change="onSearch">
              <el-option v-for="status in alarmStatusList" :key="status.value" :label="status.label" :value="status.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20" class="right-btns">
          <el-button icon="el-icon-search" class="search-box-btn" size="mini" type="primary" @click="onSearch">查询</el-button>
          <el-button icon="el-icon-upload2" class="search-box-btn" size="mini" @click="onQuickUpgrade">一键升级</el-button>
          <el-dropdown class="search-box-btn" @command="onGetReport">
            <el-button icon="el-icon-printer" size="mini" style="width:100%">导出报表</el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="selected">导出所选</el-dropdown-item>
                <el-dropdown-item command="thispage">导出当前页</el-dropdown-item>
                <el-dropdown-item command="alls">导出所有</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
          <el-button class="search-box-btn" size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="onAddDevice">添加设备</el-button>
        </el-col>
      </el-row>
    </el-form>
    </div>
    <div class="content-list-data-box">
      <device-table ref="deviceTable" mode="admin" :list="deviceList" :height="isSmallScreen ? tableHeight - 50: tableHeight" :page="pageData"
        @detail="gotoDeviceDetail" @select-change="handleSelectionChange" @reload="onSearch">
      </device-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :pager-count="7" :current-page="pageData.current" @current-change="pageChange" />
    </div>
    <el-dialog :title="title" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.adminedit" :visible="dialogVisible.adminedit" width="600px"
      @close="onCloseDialog('adminedit')"
    >
      <admin-edit ref="adminedit" :data="rowData" :visible="dialogVisible.adminedit" @close-edit-modal="onCloseDialog('adminedit', true)" />
    </el-dialog>
    <el-dialog :title="title" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.quick" :visible="dialogVisible.quick" width="1100px"
      @close="onCloseDialog('quick')"
    >
      <quick-upgrade ref="quick" :data="rowData" :visible="dialogVisible.quick" @close-edit-modal="onCloseDialog('quick', true)" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AdminEdit from "./admin-edit";
import quickUpgrade from "@/views/device/quick-upgrade";
import businessSearch from "@/components/business/search";
import DeviceTable from '@/components/device-table';

import { getDevices, downloadEquipmentDetails } from '@/api/device';

let searchParams = {
  deviceName: '',
  deviceCode: '',
  onlineStatus: '',
  projectType: '',
  projectName: '',
  tenantId: "",
  businessId: "",
  alarmStatus: '',
  updateStatus: "",
  information: "",
  order: "desc"
};
let pageData = {
  current: 1,
  size: 20,
  total: 0
};

const protoSearchObj = JSON.stringify(searchParams);
const protoPageData = JSON.stringify(pageData);

export default {
  name: 'Device',
  components: {
    AdminEdit,
    businessSearch,
    DeviceTable,
    quickUpgrade
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      utils: $utils,
      span: 4,
      loading: false,
      tableHeight: 0,
      dialogVisible: {
        adminedit: false,
        quick: false
      },
      title: "",
      upgradeTitle: "",
      orderBy: "排序方式",
      orderByBtnType: "",
      deviceList: [],
      pageData: pageData,
      projectTypeList: $statics.PROJECT_TYPE_LIST,
      updateStatusList: $statics.FIRMWARE_UPGRADE_STATUS_LIST,
      onlineStatusList: $statics.ONLINE_STATUS_LIST,
      alarmStatusList: $statics.ALARM_STATUS_LIST,
      // gwStatusList: $statics.GW_STATUS_LIST,
      searchParams: {},
      addDevicesVisible: false,
      isSmallScreen: false,
      rowData: null,
      multipleSelection: []
    };
  },
  computed: {
    ...mapGetters(['user', 'routes'])
  },
  created () {
    console.log("admin-device-list");

    this.searchParams = searchParams;
    this.isSmallScreen = window.outerWidth < 1920 || $utils.detectZoom() > 1;
    this.tableHeight = document.body.scrollHeight - 273;
    // if (this.isSmallScreen) {
    //   this.span = 6;
    //   this.tableHeight = document.body.scrollHeight - 225;
    // } else {
    // }
  },
  mounted () {
    this.$route.meta.deactivated = () => {
      this.pageData = pageData = JSON.parse(protoPageData);
      this.searchParams = searchParams = JSON.parse(protoSearchObj);
    }
    this.handleGetDevices();
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    onSearch () {
      pageData.current = 1;
      this.handleGetDevices();
    },
    initSearch () {
      const params = Object.assign({}, this.searchParams);
      params.page = (pageData.current - 1) * pageData.size;
      if (this.user.tenantId !== 0) {
        params.businessId = this.user.businessId;
      }
      params.size = pageData.size;

      return params;
    },
    // 获取设备列表
    async handleGetDevices() {
      this.loading = true;
      const params = this.initSearch();
    
      try {
        const current = pageData.current;
        const res = await getDevices(params);
        this.deviceList = res.data.map((obj) => {
          obj.updating = 0;
          return obj;
        })
        pageData = this.pageData = res.paging;
        pageData.current = current;
      } catch(e) {
        this.pageData = pageData;
        this.deviceList = [];
        this.$message.error("读取失败");
      } finally {
        this.loading = false;
      }
    },
    onSizeChange (e) {
      pageData.size = e;
      pageData.current = 1;
      this.handleGetDevices();
    },
    pageChange(e) {
      pageData.current = e;
      this.handleGetDevices();
    },
    // 添加设备
    onAddDevice () {
      this.dialogVisible.adminedit = true;
      this.title = "添加设备";
      this.rowData = null;
    },
    onEmsScreen (row) {
      // this.$router.push({ path: `/ems-screen/${row.code}` });
      window.open(`/#/ems-screen/${row.code}`);
    },
    gotoDeviceDetail(row) {
      this.$router.push({ path: `/admin-device/detail/${row.code}` });
    },
    async doExport (ids, page, size) {
      const params = {
        ids: ids,
        page: page || pageData.page,
        size: size || pageData.size
      }

      const res = await downloadEquipmentDetails(params);
      $utils.exportExcel(res);
    },
    onGetReport (command) {

      if ($utils.userReadOnly(this.user)) {
        this.$message.error("当前用户没有下载权限");
        return;
      }

      switch (command) {
        case "selected":

          const txt = this.multipleSelection.length === 0 ? "您没有选择想要导出报表的设备，是否导出本页全部报表？" : "您是否要导出您所选择的这些设备的报表？";

          this.$confirm(txt, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(async () => {
                const ids = (this.multipleSelection.length === 0) ? 
                    this.deviceList.map(obj => obj.id) :
                    this.multipleSelection.map(obj => obj.id);

                this.doExport(ids.join());
              })
              .catch((e) => {
              });
          break;
        case "thispage":
          const ids = this.deviceList.map(obj => obj.id);
          this.doExport(ids.join());
          break;
        case "alls":
          this.doExport(undefined, 1, pageData.total);
          break;
      }
    },
    onQuickUpgrade () {
      this.title = "一键升级";
      this.dialogVisible.quick = true;
    },
    async onCloseDialog (refName, ignore) {
			// 通过 emit 发送的关闭请求一律通过
			// 如果点击 close 图标则一律直接关闭
			if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
				// 设置 visable 后,会重新触发 close 所以需要先 remove edit
				delete this.$refs[refName];
        this.dialogVisible[refName] = false;
        if (ignore) {
          this.handleGetDevices();
        }
			}
    }
  }
}
</script>