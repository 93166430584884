var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "edit-items-box",
          staticStyle: { "max-height": "560px", overflow: "auto" },
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "headName", label: "设备名称" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "20",
                  placeholder: "请输入设备名称",
                },
                model: {
                  value: _vm.form.headName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "headName", $$v)
                  },
                  expression: "form.headName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "deviceType", label: "设备类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "请选择设备类型",
                  },
                  on: { change: _vm.onSelectProjectType },
                  model: {
                    value: _vm.form.deviceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deviceType", $$v)
                    },
                    expression: "form.deviceType",
                  },
                },
                _vm._l(_vm.pTypes, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("device-edit-code", {
            attrs: { data: null, form: _vm.form, multiple: true },
          }),
          _c("biz-edit", {
            ref: "bizEdit",
            attrs: { "is-admin": _vm.user.tenantId === 0, dataObj: _vm.form },
            on: { "select-company": _vm.onSelectCompnay },
          }),
          _c(
            "el-form-item",
            { attrs: { prop: "consignee", label: "收货人姓名" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c("span", [_vm._v("收货人姓名")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { placement: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: {
                          "font-size": "16px",
                          "vertical-align": "middle",
                        },
                      }),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("请填写自己的真实姓名")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "请输入收货人姓名",
                },
                model: {
                  value: _vm.form.consignee,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "consignee", $$v)
                  },
                  expression: "form.consignee",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "customerPhone", label: "客户电话" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "11",
                  placeholder: "请输入客户电话",
                },
                model: {
                  value: _vm.form.customerPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "customerPhone", $$v)
                  },
                  expression: "form.customerPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "address", label: "收货地址" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "请输入收货地址",
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "deliveryTime", label: "发货时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  clearable: "",
                  size: "mini",
                  placeholder: "请选择发货时间",
                },
                model: {
                  value: _vm.form.deliveryTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deliveryTime", $$v)
                  },
                  expression: "form.deliveryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "tabulationName", label: "负责人姓名" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "请输入负责人姓名",
                },
                model: {
                  value: _vm.form.tabulationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tabulationName", $$v)
                  },
                  expression: "form.tabulationName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "headPhone", label: "负责人电话" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c("span", [_vm._v("负责人电话")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { placement: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: {
                          "font-size": "16px",
                          "vertical-align": "middle",
                        },
                      }),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("请填写自己的真实姓名")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "11",
                  placeholder: "请输入负责人电话",
                },
                model: {
                  value: _vm.form.headPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "headPhone", $$v)
                  },
                  expression: "form.headPhone",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "testName", label: "测试人员姓名" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c("span", [_vm._v("测试人员姓名")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      staticStyle: { "margin-left": "5px" },
                      attrs: { placement: "right" },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-warning",
                        staticStyle: {
                          "font-size": "16px",
                          "vertical-align": "middle",
                        },
                      }),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("请填写自己的真实姓名")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  placeholder: "请输入测试人员姓名",
                },
                model: {
                  value: _vm.form.testName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "testName", $$v)
                  },
                  expression: "form.testName",
                },
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "testPhone", label: "测试人员电话" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  maxlength: "11",
                  placeholder: "请输入测试人员电话",
                },
                model: {
                  value: _vm.form.testPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "testPhone", $$v)
                  },
                  expression: "form.testPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "area", label: "所在地" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择所在地",
                    size: "mini",
                    clearable: "",
                    disabled: _vm.isI1 || _vm.readOnly,
                  },
                  model: {
                    value: _vm.form.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "area", $$v)
                    },
                    expression: "form.area",
                  },
                },
                _vm._l(_vm.areaList, function (area) {
                  return _c("el-option", {
                    key: area,
                    attrs: { value: area, label: area },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "remarks", label: "备注" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  size: "mini",
                  type: "textarea",
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.form.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remarks", $$v)
                  },
                  expression: "form.remarks",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-footer", {
        attrs: {
          "reset-access": "device-edit-reset-btn",
          "submit-access": "device-edit-edit-btn",
        },
        on: { reset: _vm.onReset, submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }