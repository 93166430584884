<template>
    <div class="big-data-container">
        <div class="content">
            <div v-if="user.tenantId === 0">
                <div v-if="isEnteredPasswd">
                    <list />
                </div>
                <div v-else class="passwd-box">
                    <el-form>
                        <el-form-item label="请输入管理员密码：">
                            <el-input size="mini" v-model="passwd" />
                        </el-form-item>
                    </el-form>
                    <div class="right-btns m-t-10">
                        <el-button type="primary" size="mini" @click="onCheckPassword">确定</el-button>
                    </div>
                </div>
            </div>
            <div v-else>您暂时没有操作该页面的权限</div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import list from  "./list";

export default {
    computed: {
        ...mapGetters(['user'])
    },
    components: {
        list
    },
    data () {
        return {
            isEnteredPasswd: false,
            passwd: ""
        }
    },
    methods: {
        onCheckPassword () {
            const re = this.passwd === $utils.formatTime(new Date(), "YYYYMMDD");
            this.isEnteredPasswd = re;
        }
    }
}
</script>
<style lang="scss" scoped>
.passwd-box {
    width: 400px;
    margin: auto;
    margin-top:25vh;
}
</style>